<template>
  <div class="employee-debt">
    <Loader
      v-if="loaderEnabled"
      v-bind:logo="loaderLogo"
    ></Loader>
    <KTCodePreview v-bind:title="'Nhập liệu công nợ nhân viên'">
      <template v-slot:toolbar> </template>
      <template v-slot:preview>
        <p><b>Ngày nhập: </b>{{ importDate }}</p>
        <div class="mt-1">
          <b-row>
            <b-col>
              <span> <b>Bước 1:</b> Vui lòng chọn loại dữ liệu cần nhập </span>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="3">
              <date-picker
                class="mt-3 form-control form-control-sm"
                v-model="debtMonthSelected"
                :config="dpConfigs.month"
                placeholder="Chọn tháng công nợ"
                v-b-tooltip.hover
                title="Chọn tháng công nợ"
              ></date-picker>
            </b-col>
          </b-row>
        </div>
        <div
          v-if="isDisplayHtml('import-excel')"
          class="mt-4"
        >
          <b-row>
            <b-col>
              <span> <b>Bước 2:</b> Nhập dữ liệu bằng excel. Vui lòng chuẩn bị dữ liệu mẫu từ file excel đúng
                format, hoặc có thể tải ở
                <a :href="urlExcel">đây</a> </span>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <UploadFileMulter
                v-on:send-file="getDataFromExcel"
                v-on:clear-file="clearFileExcel"
                :cmdUrl="'employee-debts/uploads/'"
              />
              <span v-if="fileName">Tên file: <b>{{ fileName }}</b>
              </span>
            </b-col>
          </b-row>
        </div>
        <div
          v-show="mode === 'update' || isDisableHtml('file-has-data')"
          class="mt-4"
        >
          <b-row class="mt-3">
            <b-col cols="3">
              <b-row>
                <b-col>
                  <span> <b>Bước 3:</b> Nhập thông tin </span>
                </b-col>
              </b-row>
              <div class="mt-2">
                <b-row>
                  <b-col>
                    <b-input
                      size="sm"
                      placeholder="Tên giải ngân công nợ"
                      autocomplete="off"
                      v-model="name"
                    /></b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col>
                    <b-form-textarea
                      size="sm"
                      class="input-style"
                      placeholder="Thêm ghi chú"
                      :rows="3"
                      :max-rows="3"
                      v-model="note"
                    ></b-form-textarea>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col v-if="employeeDebtConvertedBackUps.length">
                    <b-overlay
                      :show="isBusy"
                      rounded
                      block
                      opacity="0.6"
                    >
                      <template #overlay>
                        <div class="d-flex align-items-center">
                          <b-spinner
                            variant="light"
                            small
                            type="grow"
                          ></b-spinner>
                        </div>
                      </template>
                      <b-button
                        block
                        variant="primary"
                        size="sm"
                        class="mt-6"
                        :disabled="!employeeDebtConvertedBackUps.length"
                        @click="onUpsertEmployeeDebt"
                      >
                        <strong>{{
                          mode === 'update' ? 'Cập nhật' : 'Tạo mới'
                        }}</strong>
                      </b-button>
                    </b-overlay>
                  </b-col>
                </b-row>
              </div>
            </b-col>
            <b-col cols="9">
              <div v-if="isDisplayHtml('match-data')">
                <hr
                  class="hr-text"
                  data-content="Danh sách kết nối dữ liệu"
                  style="font-weight: 600"
                />

                <b-row>
                  <b-col>
                    <div>
                      <b-overlay
                        rounded
                        opacity="0.6"
                      >
                        <template #overlay>
                          <div class="d-flex align-items-center">
                            <b-spinner
                              variant="light"
                              small
                              type="grow"
                            ></b-spinner>
                          </div>
                        </template>
                        <b-dropdown
                          size="sm"
                          class="mb-4"
                          right
                        >
                          <template slot="button-content">
                            <i
                              style="font-size: 1rem"
                              class="fas fa-cog"
                            ></i>
                            <span class="font-weight-bolder">Xuất excel</span>
                          </template>
                          <b-dropdown-item @click="onExportToEmployeeDebt">
                            <i class="far fa-file-excel mr-1"></i>
                            <span> Công nợ</span>
                          </b-dropdown-item>
                        </b-dropdown>
                      </b-overlay>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div v-show="employeeDebtConvertedBackUps.length !== 0">
                      <div class="table-responsive">
                        <b-table
                          show-empty
                          responsive
                          bordered
                          hover
                          :items="debtConverteds"
                          :fields="employeeDebtConvertedFields"
                          :per-page="10"
                          :current-page="currentPage"
                        >
                          <template #empty>
                            <div class="text-center">Không có dữ liệu</div>
                          </template>
                          <template #cell(totalAmount)="data">
                            {{ convertPrice(data.item.totalAmount) }}
                          </template>
                          <template #cell(statusAttribute)="data">
                            <b-badge :variant="
                                data.item.statusAttribute
                                  ? data.item.statusAttribute.label
                                  : 'danger'
                              ">{{
                                data.item.statusAttribute
                                  ? data.item.statusAttribute.statusName
                                  : 'Lỗi'
                              }}</b-badge>
                          </template>
                          <template #cell(actions)="data">
                            <i
                              class="
                                d-block
                                fa fa-trash
                                text-danger
                                cursor-pointer
                                ml-2
                              "
                              @click="removeItem(data.item, null, 'header')"
                              aria-hidden="true"
                            ></i></template>
                        </b-table>
                      </div>

                      <b-col class="row mt-3">
                        <div class="title-sum">
                          <ul class="list-inline d-flex">
                            <li class="mr-2">
                              <b>Tổng tiền:
                                <span class="text-success">{{
                                  convertPrice(formattedTotalPrice)
                                }}</span>
                              </b>
                            </li>
                            <li
                              class="mr-2 cursor-pointer"
                              @click="filterEmployeeDebt()"
                            >
                              <b>
                                Tổng số dòng:
                                <span lass="text-success">
                                  {{
                                    employeeDebtConvertedBackUps
                                      ? employeeDebtConvertedBackUps.length
                                      : 0
                                  }}
                                </span>
                              </b>
                            </li>
                          </ul>
                        </div>
                      </b-col>
                      <b-pagination
                        v-show="debtConverteds.length > 5"
                        v-model="currentPage"
                        :total-rows="rows()"
                        :per-page="10"
                        align="right"
                      ></b-pagination>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </div>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import {
  cloneDeep,
  convertArrayToObject,
  convertPrice,
  makeToastFaile,
} from '@/utils/common';
import fileDownload from '@/utils/file-download';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { makeToastSuccess } from '@/utils/common';
import { BASE_URL } from '@/utils/constants';

const KTCodePreview = () =>
  import(/* webpackPrefetch: true */ '@/view/content/CodePreview.vue');
const Loader = () =>
  import(/* webpackPrefetch: true */ '@/view/content/Loader.vue');
const UploadFileMulter = () =>
  import(/* webpackPrefetch: true */ '@/view/modules/upload/UploadFileMulter');

const thStyleCommon = {
  textAlign: 'center',
  fontWeight: 600,
  color: '#181c32',
  width: '5%',
};

export default {
  data() {
    return {
      dpConfigs: {
        month: {
          format: 'MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      debtMonthSelected: null,
      title: 'Nhập liệu công nợ nhân viên',
      defaultEmployeeDebtConfigFields: [
        {
          key: 'source',
          label: 'Hệ thống',
          thStyle: {
            ...thStyleCommon,
            width: '5%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'reference',
          label: 'Excel',
          thStyle: {
            ...thStyleCommon,
            width: '10%',
          },
          tdClass: 'text-center',
        },
      ],
      debtConverteds: [],
      employeeDebtConvertedFields: [],
      employeeDebtConfigs: [],
      employeeDebtConfigFields: [],
      debtRaws: [],
      debtRawFields: [],
      currentPage: 1,
      currentPageRaw: 1,
      name: null,
      note: null,
      mode: null,
      importDate: moment().format('DD/MM/YYYY'),
      fileName: null,
      employeeDebtConvertedBackUps: [],
      employeeDebtId: null,
      isBusy: false,
      debtSelected: null,
      urlExcel: BASE_URL + 'media/upload/debt/Temp_Import_Employee_Debt_.xls',
    };
  },
  components: {
    KTCodePreview,
    Loader,
    UploadFileMulter,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.title }]);
  },
  beforeDestroy() {},
  created() {
    const id = this.$route.params.id;
    if (id) {
      this.mode = 'update';
      this.employeeDebtId = id;
      this.getEmployeeDebtById(id);
    }
  },
  methods: {
    convertPrice,
    isDisplayHtml(type) {
      switch (type) {
        case 'import-excel': {
          return this.debtMonthSelected;
        }
        case 'match-data': {
          return this.employeeDebtConvertedBackUps.length;
        }
      }
    },
    isDisableHtml(type, value) {
      switch (type) {
        case 'file-has-data': {
          return this.debtRaws.length > (value || 0) ? true : false;
        }
      }
    },
    rows() {
      return this.debtConverteds.length;
    },
    rowRaws() {
      return this.debtRaws.length;
    },
    getDataFromExcel(data, fileName) {
      this.employeeDebtConvertedBackUps = [];
      this.fileName = fileName;
      const debtRaws = [...data.rows].map((item) => {
        return {
          ...item,
        };
      });

      const headers = data.headers;
      this.debtRawFields = Object.values(headers).reduce((acc, cur) => {
        const item = {
          key: cur.field,
          label: cur.field,
          thStyle: {
            ...thStyleCommon,
            width: '5%',
          },
          tdClass: 'text-center',
          tdStyle: {
            ...thStyleCommon,
            width: '5%',
          },
        };

        acc.push(item);
        return acc;
      }, []);

      this.debtRaws = cloneDeep(debtRaws);
      this.setConfigDebt(data.headersRequired);
      this.onMatchingDataExcel();
    },
    setConfigDebt(headersRequired) {
      this.employeeDebtConfigs = this.getHeadersRequired(headersRequired);
      this.employeeDebtConfigFields = this.defaultEmployeeDebtConfigFields;
    },
    getHeadersRequired(headersRequired) {
      return Object.values(headersRequired).reduce((acc, cur) => {
        acc.push({
          source: cur.name,
          reference: cur.key,
          refSource: cur.key,
          refKey: cur.refKey,
          required: cur.required,
        });
        return acc;
      }, []);
    },
    onMatchingDataExcel() {
      const [isValid, message] = this.validateMatchingDataExcel();
      if (!isValid) {
        makeToastFaile(message);
        return;
      }

      const importHeadersKey = convertArrayToObject(
        this.employeeDebtConfigs,
        'reference',
      );
      const fieldsRaw = cloneDeep(this.debtRawFields).filter((item) => {
        const headersInstead = importHeadersKey[item.key];
        if (headersInstead) {
          item.key = headersInstead.refSource;
          item.label = headersInstead.source;
          return item;
        }
      });

      this.employeeDebtConvertedFields = cloneDeep(fieldsRaw);

      const debtRaws = this.debtRaws.map((element) => {
        const item = {};
        for (const [key, value] of Object.entries(element)) {
          if (key && key.length) {
            const headersInstead = importHeadersKey[key];
            if (headersInstead) {
              item[headersInstead.refSource] = value;
            }
          }
        }
        return item;
      });

      this.debtConverteds = cloneDeep(debtRaws);
      this.employeeDebtConvertedBackUps = cloneDeep(debtRaws);
    },
    validateMatchingDataExcel() {
      for (const config of this.employeeDebtConfigs) {
        if (config.required && !config.reference) {
          return [false, `Yêu cầu ${config.source}`];
        }
      }
      return [true, 'Hợp lệ'];
    },
    clearFileExcel() {
      this.debtConverteds = [];
      this.employeeDebtConvertedBackUps = [];
    },
    onUpsertEmployeeDebt() {
      const data = {
        id: this.employeeDebtId || null,
        debtMonth: this.debtMonthSelected,
        name: this.name,
        note: this.note,
        employeeDebtConverteds: this.employeeDebtConvertedBackUps,
        employeeDebtConfigFields: this.employeeDebtConfigFields,
        employeeDebtConvertedFields: this.employeeDebtConvertedFields,
        importDate: this.importDate,
        fileName: this.fileName,
      };
      const method = this.mode === 'update' ? 'put' : 'post';
      this.isBusy = true;
      ApiService[method]('/employee-debts/', data)
        .then((response) => {
          const { message } = response.data;
          this.$router.push({
            name: 'employee-debt-list',
          });
          makeToastSuccess(message);
          this.isBusy = false;
        })
        .catch((err) => {
          this.isBusy = false;

          if (!err.response) {
            // network error
            makeToastFaile(err.response);
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
        });
    },
    getEmployeeDebtById(id) {
      ApiService.get(`/employee-debts/${id}`)
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            this.name = data.name;
            this.note = data.note;
            this.debtConverteds = data.employeeDebts;
            this.employeeDebtConvertedBackUps = data.employeeDebts;
            this.employeeDebtConfigFields = data.employeeDebtConfigFields;
            this.employeeDebtConvertedFields = data.employeeDebtConvertedFields;
            this.importDate = data.importDate;
            this.fileName = data.fileName;
            this.debtMonthSelected = data.debtMonth;
          }
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            makeToastFaile(err.response);
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
        });
    },
    onExportToEmployeeDebt() {
      const body = {
        employeeDebts: this.employeeDebtConvertedBackUps,
      };

      ApiService.download(`/employee-debts/convert`, body, {
        responseType: 'arraybuffer',
      })
        .then((response) => {
          const fileName = fileDownload.getFileName(response);
          fileDownload.createFile(response.data, fileName);
        })
        .catch((err) => {
          if (!err.response) {
            // network error

            makeToastFaile(err.response);
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
        });
    },
    filterEmployeeDebt(name) {
      switch (name) {
        case 'valid': {
          this.debtConverteds = this.employeeDebtConvertedBackUps.filter(
            (item) => item.entities && item.entities.length === 1,
          );
          break;
        }
        case 'valid-mul': {
          this.debtConverteds = this.employeeDebtConvertedBackUps.filter(
            (item) => item.entities && item.entities.length > 1,
          );
          break;
        }
        case 'invalid': {
          this.debtConverteds = this.employeeDebtConvertedBackUps.filter(
            (item) => item.entities && item.entities.length === 0,
          );
          break;
        }
        case 'no-match-document': {
          this.debtConverteds = this.employeeDebtConvertedBackUps.filter(
            (item) => {
              return (
                (item.entities || []).filter((entity) => entity.noMatching)
                  .length > 0
              );
            },
          );
          break;
        }
        default: {
          this.debtConverteds = cloneDeep(this.employeeDebtConvertedBackUps);
          break;
        }
      }
    },
    removeItem(header, detail, type) {
      switch (type) {
        case 'detail': {
          const indexHeader = this.debtConverteds.findIndex(
            (item) => item.id === header.id,
          );
          if (indexHeader > -1) {
            const indexDetail = this.debtConverteds[
              indexHeader
            ].entities.findIndex((item) => item.id === detail.id);
            if (indexDetail > -1) {
              this.debtConverteds[indexHeader].entities.splice(indexDetail, 1);
            }
          }
          this.employeeDebtConvertedBackUps = cloneDeep(this.debtConverteds);
          break;
        }
        case 'header': {
          const indexHeader = this.debtConverteds.findIndex(
            (item) => item.id === header.id,
          );
          if (indexHeader > -1) {
            this.debtConverteds.splice(indexHeader, 1);
          }
          this.employeeDebtConvertedBackUps = cloneDeep(this.debtConverteds);
          break;
        }
      }
    },
  },
  computed: {
    ...mapGetters(['breadcrumbs', 'pageTitle', 'layoutConfig']),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return this.layoutConfig('loader.logo');
    },
    formattedTotalPrice() {
      return this.debtConverteds.reduce((result, item) => {
        return result + Number(item.debtTotalAmount) || 0;
      }, 0);
    },
    isModeUpdate() {
      return this.mode === 'update';
    },
  },
  watch: {
    debtMonthSelected(newValue) {
      if (!newValue) {
        this.debtMonthSelected = null;
        return;
      }
    },
  },
};
</script>

<style lang="scss">
.employee-debt {
  table {
    table-layout: fixed;
    width: auto;
    word-wrap: break-word;
  }
  .cursor-pointer {
    cursor: pointer;
  }
}
</style>
